import React from "react";
import contentForHomePage from "../data/contentForHomePage.json";
import Text from "../common/text";
import { commonStyles } from "../styles/commonStyles";
import { createDynamicClass } from "../styles/common";
import { PayAPrice } from "../images/payAPrice.jpeg"

const classes = createDynamicClass({
  textContainer: {
    margin: "0 10%",
  },
});
const Home = () => {
  let records = contentForHomePage.records;
  let checkEmpty = (content) => content !== "";
  return (
    <div className={classes.textContainer}>
      {records.map((record) => {
        return (
          <>
            {checkEmpty(record[0]) && (
              <Text content={record[0]} classes={commonStyles.sectionHeader} />
            )}
            <Text content={record[1]} classes={commonStyles.textBody} />
            {checkEmpty(record[2]) && (
              <Text content={record[2]} classes={commonStyles.asciiLineSeparator} />
            )}
          </>
        );
      })}
    </div>
  );
};

export default Home;
