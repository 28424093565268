import React, { useState } from 'react';
import './input.css';


const CInput = ({ label, value, onChange ,disabled = false ,texttype='text' ,classes=''}) => {
  const [isFocused, setIsFocused] = useState(false);
 var inputVal =''
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    inputVal = inputValue
    onChange(inputValue);
  };
  const handleInputFocus = () => {  
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    if(!inputVal)
    setIsFocused(false);
  };

  return (
    <div className={`floating-label-input ${isFocused ? 'focused' : ''}`}>
     
      <input
        type={texttype}
        value={value}
        placeholder={label}
        className={classes}
        disabled ={disabled}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={(value)=>handleInputBlur(value)}
      />
    </div>
  );
};

export default CInput;
