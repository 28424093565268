import React from "react";
import { createDynamicClass } from "../styles/common";

const classes = createDynamicClass({
  linkContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    marginBottom: "20px",
  },
  name: {
    fontSize: "20px",
    color: "#B4BF35",
    textAlign: "center",
  },
  credit: {
    fontSize: "15px",
    color: "#B4BF35",
    textAlign: "center",
  },
  socialMediaLinks: {
    fontSize: "20px",
    textDecoration: "none",
  },
  upDatedText: {
    textAlign: "center",
    fontSize: "16px",
    color: "#9EC83C",
    margin: "40px 10px 10px 10px ",
  },
});

const links = {
  twitter: { link: "https://twitter.com/vickyb", color: "#33FAFF" },
  vicky: { link: "https://vicky.ca", color: "#E9BC3F" },
  github: { link: "https://github.com/nicholhai", color: "#EB4888" },
  LinkedIn: {
    link: "https://www.linkedin.com/in/vicky-bagwalla/",
    color: "#10A2F5",
  },
  wallet: {
    link: "https://unstoppabledomains.com/d/vickybagwalla.wallet",
    color: "#24D05A",
  },
};
function Footer() {
  return (
    <>
      <div className={classes.upDatedText}>
        <p>[*] Change Log: Last Updated: September-04-2024</p>
        <p className={classes.name}>Vicky Bagwalla</p>
        <p className={classes.credit}>Contribution Credit to Arjun Puri</p>
      </div>
      <div className={classes.linkContainer}>
        {Object.entries(links).map((item,index) => {
          return (
            <div key={`index-${index}`}>
              <a
                target="_blank"
                href={item[1].link}
                rel="noreferrer"
                style={{ color: item[1]?.color }}
                className={classes.socialMediaLinks}
              >
                {item[0]}
              </a>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Footer;
