import React from "react";
import parse from 'html-react-parser';

function text(props) {
  const { content, style, classes , onClick = ()=>{}} = props;
  return (
    <p style={style} className={classes} onClick={onClick}>
      {parse(content)}
    </p>
  );
}

export default text;
