import emailjs from 'emailjs-com';

export const initializeEmailJS = () => {
  emailjs.init('bspb0gAFrB5hx4r5m');
};
export const sendEmailForAccess =(name,email,content)=>{

    var data = {
        service_id: 'service_k7dwvgy',
        template_id: 'template_x9250nq',
        user_id: 'bspb0gAFrB5hx4r5m',
        template_params: {
            'from_name': name,
            'to_name':'Vicky',
            'message':content,
            'email':email
        }
    };
     
    fetch('https://api.emailjs.com/api/v1.0/email/send', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          if (response.ok) {
            alert('Your request for access is sent!');
          } else {
            throw new Error('Oops... ' + response.status);
          }
        })
        .catch(error => {
          alert('Oops... ' + error);
        });
      
}
