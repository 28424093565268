import React from "react";
import { useState } from "react";
import contentForMembersOnly from "../data/contentForMembersOnly.json";
import Text from "../common/text";
import { commonStyles } from "../styles/commonStyles";
import { createDynamicClass } from "../styles/common";
const classes = createDynamicClass({
  textContainer: {
    margin: "0 10%",
  },
  mobileMenu:{
    mobile:{
      whiteSpace:'nowrap',
      borderBottom:' solid 1px white'
    }
  },
  active:{
    backgroundColor:'black'
  }
});
const MembersOnly = () => {
  let checkEmpty = (content) => content !== "";
  let records = contentForMembersOnly.records.filter(record => checkEmpty(record[3]) && checkEmpty(record[1])).reverse();
  let [index, setIndex] = useState(0);
  
  let menuClick = (e) => {
    setIndex(e);
  };
  return (
    <div>
      {contentForMembersOnly.column[3] === "menuText" && (
        <div className={commonStyles.floatingMenu}>
          {/* <ul> */}
            {records.map((record, recindex) => {
              return (
                checkEmpty(record[3]) && (
                  <Text
                    content={record[3]}
                    key={recindex}
                    onClick={() => menuClick(recindex)}
                    classes={`${commonStyles.floatingMenuText} ${classes.mobileMenu} ${recindex===index?classes.active:''}`}
                  />
                )
              );
            })}
          {/* </ul> */}
        </div>
      )}
      <div className={classes.textContainer}>
        {checkEmpty(records[index][0]) && (
          <Text
            content={records[index][0]}
            classes={commonStyles.snippetsHeader}
          />
        )}
        <Text content={records[index][1]} classes={commonStyles.textBody} />
        {checkEmpty(records[index][2]) && (
          <Text
            content={records[index][2]}
            classes={commonStyles.asciiLineSeparator}
          />
        )}
      </div>
    </div>
  );
};

export default MembersOnly;
