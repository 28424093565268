import React from "react";
import { connect } from "react-redux";
import { useState ,  useEffect} from "react";
import { createDynamicClass } from "../styles/common";
import { Link } from "react-router-dom";
import AttitudeBanner from "../images/attitudebanner.png";

const classes = createDynamicClass({
  header: {
    display: "flex",
    listStyle: "none",
    justifyContent: "space-evenly",
    margin: "20px 0",
    marginTop: "10px",
    paddingBottom: "20px",
    alignItems: "center",
    borderBottom: "1px solid yellow",
    mobile:{
      padding: "0 0 20px"
    }
  },
  links: {
    color: "#DAF7A6",
    textDecoration: "none",
    width: "50%",
    alignItems: "center",
  },
  app: {
    minWidth: "0px",
    backgroundColor: "#3D3D3D",
    height: "100%",
    width: "50%",
    alignItems: "center",
  },
  banner: {
    width: "100%" /* or any custom size */,
    objectFit: "contain",
  },
  ImageContainer: {
    padding: "25px 12%",
  },
});

function Header(props) {
  const { user } = props;
  var isLogged = user.login;
  useEffect(()=>{
    isLogged = props.user.login;
  },[isLogged, props.user])
  return (
    <>
      <header>
        <nav>
          <ul className={classes.header}>
         <li>
              <Link to="/" className={classes.links}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/TheLaws" className={classes.links}>
                The Laws
              </Link>
            </li>
            <li>
           <Link to="/Snippets" className={classes.links}>
                Snippets
              </Link>
            </li>
            <li>
           <Link to="/Poetry" className={classes.links}>
                Poetry
              </Link>
            </li>
            { isLogged &&  <li>
           <Link to="/MembersOnly" className={classes.links}>
                Members Only
              </Link>
            </li>}
            <li>
              <Link to="/AboutMe" className={classes.links}>
                About
              </Link>
            </li>
            <li>
              <Link to="/Login" className={classes.links}>
                {isLogged? user?.name:'Login/SignUp'}
              </Link>
            </li>
          </ul>
        </nav>
      </header>
      <div className={classes.ImageContainer}>
        <img
          src={AttitudeBanner}
          alt="Attitude is Everything"
          className={classes.banner}
        ></img>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps) (Header);
