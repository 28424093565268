function createStyles(obj) {
  return Object.keys(obj)
    .map((key) => {
      let cssKey = key.replace(/[A-Z]/g, (m) => "-" + m.toLowerCase());
      return `${cssKey}: ${obj[key]};`;
    })
    .join("");
}

export function createDynamicClass(obj) {
  let classObj = {};
  for (let cstyles in obj) {
    let hasMediaQuery = false;
    let hasEvents = false;

    if (obj[cstyles].mobile) {
      hasMediaQuery = true;
    }

    if (obj[cstyles].events) {
      hasEvents = true;
    }

    const filteredObj = Object.fromEntries(
      Object.entries(obj[cstyles]).filter(
        ([key, value]) => key !== "mobile" && key !== "events"
      )
    );

    const defaultClassName = createStyle(filteredObj);
    const mediaQueryClassName = hasMediaQuery ? createMediaQuery('@media (max-width: 1024px)', obj[cstyles].mobile) : '';
    const eventClassName = hasEvents ? createEvents(obj[cstyles].events,defaultClassName) : '';

    classObj[cstyles] = `${defaultClassName} ${mediaQueryClassName} ${eventClassName}`;
  }
  return classObj;
}

export function createStyle(obj) {
  const className = `my-class-${Math.random().toString(36).substring(7)}`;
  const styles = `.${className} { ${createStyles(obj)} }`;

  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = styles;

  document.head.appendChild(styleSheet);

  return className;
}

export function createMediaQuery(query, styles) {
  const className = `my-class-${Math.random().toString(36).substring(7)}`;
  const mediaQuery = `${query} { .${className} { ${createStyles(styles)} } }`;
  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = mediaQuery;
  document.head.appendChild(styleSheet);
  return className;
}

export function createEvents(events,className) {
  const eventHandler = (event) => {
    events[event].call(this, event);
  };

  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";

  let combinedStyles = "";

  for (let event in events) {
    if (event === "focus") {
      combinedStyles += `.${className}:focus { ${createStyles(
        events[event]
      
      )} }`;
    } else if (event === "hover") {
      combinedStyles += `.${className}:hover { ${createStyles(
        events[event]
      )} }`;
    } else if (event === "active") {
      combinedStyles += `.${className}:active { ${createStyles(
        events[event]
      )} }`;
    }

    const elements = document.querySelectorAll(`.${className}`);
    elements.forEach((element) => {
      element.addEventListener(event, eventHandler);
    });
  }

  styleSheet.innerText = combinedStyles;
  document.head.appendChild(styleSheet);

  return className;
}
