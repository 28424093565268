import { createDynamicClass } from "./common";
export const commonStyles = createDynamicClass({
  
  floatingMenu:{
    position:'fixed',
    left:'1%',
    padding:'5%',
    top:'6%',
    height:'50rem',
    overflow:'scroll',
    mobile:{
      display:'flex',
      position:'unset',
      height:'auto',
      alignItems:'center',
      margin:'0 4%',
      gap:'10px',
      padding:'0',
    }
  },
  floatingMenuText:{
    color:'#DAF7A6',
    cursor:'pointer'
  },
  flex: {
    display: "flex",
  },
  attitudeHeading: {
    fontSize: "25px",
    color: "#c8c8c9",
    margin: "10px 0",
    letterSpacing: "1px",
    wordSpacing: "4px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    alignItems: "center",
    margin: "0px 0",
    padding: "0px 10%",
  },
  textContainer: {
    textAlign: "justified",
    letterSpacing: "1px",
    wordSpacing: "4px",
    color: "#c8c8c9",
    margin: "10px 10px",
    width: "80%",
  },
  textBody: {
    textAlign: "justified",
    letterSpacing: "1px",
    wordSpacing: "4px",
    color: "#c8c8c9",
    margin: "20px 10px",
    width: "100%",
  },
  poetrytextBody: {
    letterSpacing: "1px",
    textAlign: "center",
    wordSpacing: "4px",
    color: "#ecd91d",
    margin: "20px 10px",
    width: "100%",
  },
  upDatedText: {
    fontSize: "16px",
    color: "#F8AA0D",
    margin: "10px 0",
    letterSpacing: "1px",
    wordSpacing: "4px",
  },
  linkContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "50%",
    letterSpacing: "1px",
    wordSpacing: "4px",
  },
  name: {
    fontSize: "20px",
    color: "#868281",
    textAlign: "center",
    letterSpacing: "1px",
    wordSpacing: "4px",
  },
  sectionHeader: {
    fontSize: "20px",
    color: "#38AEC5",
    letterSpacing: "1px",
    wordSpacing: "4px",
  },
  snippetsHeader: {
    fontSize: "18px",
    fontStyle: "italic",
    textAlign: "center",
    color: "#FDDD0E",
    letterSpacing: "1px",
    wordSpacing: "4px",
  },
  poetryHeader: {
    fontSize: "20px",
    fontStyle: "bold",
    textAlign: "center",
    color: "#9ec83c",
    letterSpacing: "1px",
    wordSpacing: "4px",
  },
  socialMediaLinks: {
    fontSize: "16px",
    textDecoration: "none",
    letterSpacing: "1px",
    wordSpacing: "4px",
  },
  asciiLineSeparator: {
    textAlign: "center",
    fontSize: "25px",
    color: "#F7A139",
    margin: "40px 10px 10px 10px ",
  },
  inputBox:{
    color:'yellow',
    events:{focus:{
      color:'orange'
    }}
  },
  error:{
    color:'red'
  },
  splitter: {
    gap: "5px",
    justifyContent: "center",
    marginBottom: "5%",
    alignItems: "center",
  },
  icons: {
    cursor: "pointer",
    color: "yellow"
  },
  button: {
    display: "inline-block",
    padding: "10px 20px",
    margin: "10px",
    textAlign: "center",
    textDecoration: "none",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
});
