import React from "react";
import { useState } from 'react';
import contentForTheLaws from "../data/contentForTheLaws.json";
import Text from "../common/text";
import { commonStyles } from "../styles/commonStyles";
import { createDynamicClass } from "../styles/common";
const classes = createDynamicClass({
  textContainer: {
    margin: "0 10%",
  },
});
const TheLaws = () => {
  let records = contentForTheLaws.records;
  let [index, setIndex] = useState(0);
  let checkEmpty = (content) => content !== "";
  let menuClick = (e)=>{setIndex(e)}

  return (
    <div>
   { contentForTheLaws.column[3]==='menuText' && <div className={commonStyles.floatingMenu}>
        <ul>
        {records.map((record,index) => {return  checkEmpty(record[3]) && (
                <Text
                  content={record[3]}
                  onClick = {()=>menuClick(index)}
                  classes={commonStyles.floatingMenuText}
                />
              )} )}
        </ul>
      </div>}
      <div className={classes.textContainer}>
        {/* {records.map((record) => {
          return (
            <div id={createID(record[3])}>
              {checkEmpty(record[0]) && (
                <Text
                  content={record[0]}
                  classes={commonStyles.snippetsHeader}
                />
              )}
              <Text content={record[1]} classes={commonStyles.textBody} />
              {checkEmpty(record[2]) && (
                <Text
                  content={record[2]}
                  classes={commonStyles.asciiLineSeparator}
                />
              )}
            </div >
          );
        })} */}
         {checkEmpty(records[index][0]) && (
                <Text
                  content={records[index][0]}
                  classes={commonStyles.snippetsHeader}
                />
              )}
              <Text content={records[index][1]} classes={commonStyles.textBody} />
              {checkEmpty(records[index][2]) && (
                <Text
                  content={records[index][2]}
                  classes={commonStyles.asciiLineSeparator}
                />
              )}
      </div>
    </div>
  );
};

export default TheLaws;
