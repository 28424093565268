import React from "react";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import Text from "../common/text";
import { sendEmailForAccess } from "../services/emailService";
import users from "../data/users.json";
import CInput from "../common/input";
import { commonStyles } from "../styles/commonStyles";
import { setLogin, setName,setEmail } from "../store/reducers/user";

const Login = (props) => {
  const { user } = props;
  var isLogged = user.login;
  const [login, setlogin] = useState(true);
  const [showError,setError]=useState(false)

  useEffect(() => {
    isLogged = props.user.login;
  }, [props.user]);
  let signUpStart = (event) => {
    if (
      event.currentTarget.elements[0].value.length &&
      event.currentTarget.elements[1].value.length &&
      event.currentTarget.elements[2].value
    )
    {setError(false)
      sendEmailForAccess(
        event.currentTarget.elements[0].value,
        event.currentTarget.elements[1].value,
        event.currentTarget.elements[2].value
      );}
      else {setError(true)}
  };
  let loginStart = (event) => {
    event.preventDefault();
    let userName = event.currentTarget.elements[0].value;
    let cred = event.currentTarget.elements[1].value;
    if (users[userName] && users[userName].pwd === cred) {
      props.setLogin(true);
      props.setName(users[userName].name);
      props.setEmail(userName);
      setError(false)
    }
    else{
      setError(true)
    }
  };

  let logout =()=>{
    props.setLogin(false);
    props.setName('');
    props.setEmail('');
  }
  const renderSignUpForm = () => {
    return (
      <form onSubmit={signUpStart}>
        <CInput label="Name" onChange={() => {}} classes={commonStyles.inputBox}/>
        <CInput label="Email" onChange={() => {}} classes={commonStyles.inputBox}/>

        <CInput label="Reason For Access" onChange={() => {}} />
        <button type="submit" className={commonStyles.button}>
          Sign Up
        </button>{showError && <span className={commonStyles.error}>Invalid details</span>}
      </form>
    );
  };
  const renderLoginForm = () => {
    return (
      <form onSubmit={loginStart}>
        <CInput label="Email" onChange={() => {}} classes={commonStyles.inputBox}/>
        <CInput label="Password" onChange={() => {}} texttype="Password" classes={commonStyles.inputBox} />
        <button type="submit" className={commonStyles.button}>
          {isLogged ? "LogOut" : "Log In"}
        </button>{showError && <span className={commonStyles.error}>Invalid Credentials</span>}
      </form>
    );
  };

  const logOutForm =() => {
    return (
      <form onSubmit={logout}>
        <CInput label="Email" onChange={() => {}} disabled value={props.user.email} />
        <CInput label="Name" onChange={() => {}} value={props.user.name} disabled/>
        <button type="submit" className={commonStyles.button}>
         LogOut
        </button>
      </form>
    );
  };
  const toggleLogin = (isLogin) => {
    setlogin(isLogin);
  };

  const renderToggle = () => {
    return (
      <div className={`${commonStyles.flex} ${commonStyles.splitter}`}>
        <Text
          content={"LoginIn"}
          classes={commonStyles.icons}
          onClick={() => toggleLogin(true)}
        />
        <Text
          content={":"}
          classes={commonStyles.icons}
        />
        <Text
          content={"SignUp"}
          classes={commonStyles.icons}
          onClick={() => toggleLogin(false)}
        />
      </div>
    );
  };

  return (
    <>
      {renderToggle()}
      {isLogged?logOutForm():login ? renderLoginForm() : renderSignUpForm()}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    // Map the Redux store variable to a prop
    user: state.user,
  };
};

const mapDispatchToProps = {
  setLogin,
  setName,
  setEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
