import React from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import Home from "./pages/Home";
import TheLaws from "./pages/TheLaws";
import Snippets from "./pages/Snippets";
import Poetry from "./pages/Poetry";
import MembersOnly from "./pages/MembersOnly";
import { createDynamicClass } from "./styles/common";
import "./App.css";
import Header from "./common/header";
import Footer from "./common/footer";
import AboutMe from "./pages/AboutMe";
import Login from "./pages/login";

const headerNavStyle = createDynamicClass({
  header: {
    display: "flex",
    listStyle: "none",
    justifyContent: "space-evenly",
    margin: 0,
    padding: "10px 0",
    alignItems: "center",
  },
  links: {
    color: "#DAF7A6",
    textDecoration: "none",
    width: "50%",
    alignItems: "center",
  },
  app: {
    minWidth: "0px",
    backgroundColor: "#3D3D3D",
    height: "100%",
    alignItems: "center",
    margin: "0 20%",
    mobile:{
      margin:"0"
    }
  },
});

function App() {
  return (
    <HashRouter>
      <div className={headerNavStyle.app}>
        <Header />
        <main>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/TheLaws" exact component={TheLaws} />
            <Route path="/Snippets" component={Snippets} />
            <Route path="/Poetry" component={Poetry} />
            <Route path="/MembersOnly" component={MembersOnly} />
            <Route path="/AboutMe" component={AboutMe} />
            <Route path="/Login" component={Login} />
          </Switch>
        </main>
        <Footer />
      </div>
    </HashRouter>
  );
}

export default App;
